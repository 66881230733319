<template>
  <!-- <BannerSpecialPromo :content="specialPromoCTA" /> -->
  <BannerBlackFridayPromo v-if="!UserStore.has_paid_order" />
  <HeroV2 v-if="UserStore.has_paid_order" :hero="mainHeroContent" />

  <template v-if="variationAlternative">
    <Carousel :carousel="carouselContent" />
    <HeroV2 :hero="everyoneSees" />
  </template>

  <template v-else>
    <HeroV2 :hero="everyoneSees" />
    <Carousel :carousel="carouselContent" />
  </template>

  <Carousel :carousel="quotesCarousel" class="max-lg:mb-11 max-lg:mt-11" />
  <!-- <SpecialPromo :content="specialPromo" /> -->
  <HeroV2 :hero="partnerProgram" />
  <Helper :helper="helperContent" />
</template>

<script setup lang="ts">
import BannerSpecialPromo from "@components/pages/Home/BannerSpecialPromo.vue";
import Helper from "@components/pages/Home/Helper.vue";
import { nextTick, onMounted, ref } from "vue";

import helperBg from "@/assets/home/bg-helper.jpg";
import Carousel from "@/components/Carousel/Carousel.vue";
import HeroV2 from "@/components/pages/Home/Hero/v2/Hero.vue";
import SpecialPromo from "@/components/pages/Home/SpecialPromo.vue";
import { useUserStore } from "@/stores/UserStore";
import * as abtest from "@/utils/abtest";
import { FEATURE_HOME_DOBRA } from "@/utils/abtest";

import BannerBlackFridayPromo from "./BannerBlackFridayPromo.vue";

const isDomContentLoaded = ref(false);
const UserStore = useUserStore();

const specialPromoCTA = {
  title: "Atraia mais olhares para sua empresa com descontos especiais de até 20%",
  subtitle: "Novo anunciante? Escolha uma das três ofertas para sua primeira campanha.",
  image: {
    path: {
      desktop: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/special-promo.webp",
      mobile: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/special-promo.webp",
    },
    alt: "Ícone da promoção de descontos",
    width: 108,
  },
  cta: {
    label: "escolha uma oferta",
    url: "#descontos-especiais",
    target: "",
  },
};

const specialPromo = {
  title: "Atraia mais olhares para sua empresa com descontos especiais!",
  subtitle:
    "Faça sua primeira compra com até 20% de<br class='block lg:hidden'/> desconto e conquiste a atenção do seu<br class='block lg:hidden'/> público",
  image: {
    path: {
      desktop: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/special-promo.webp",
      mobile: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/special-promo.webp",
    },
    alt: "Ícone da promoção de descontos",
    width: 135,
  },
  promo: {
    placeholder: {
      title: "Aqui, tem desconto <br> para você",
      disclaimer: "escolha o seu acima",
      image: {
        path: {
          desktop: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/gold.webp",
          mobile: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/gold.webp",
        },
        alt: "",
        width: 56,
      },
    },
    items: [
      {
        id: "gold",
        cta: {
          title: "20%",
          subtitle: "OFF",
          highlight: true,
        },
        content: {
          tag: {
            title: "OLHAR20",
          },
          title: "20% OFF",
          disclaimer: "Válido para primeira compra,<br> investindo acima de R$ 5.000,00.",
          image: {
            path: {
              desktop: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/gold.webp",
              mobile: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/gold.webp",
            },
            width: 56,
          },
          coupon: {
            code: "OLHAR20",
            title: "Cupom de 20% OFF",
          },
        },
      },
      {
        id: "silver",
        cta: {
          title: "15%",
          subtitle: "OFF",
          highlight: false,
        },
        content: {
          tag: {
            title: "OLHAR15",
          },
          title: "15% OFF",
          disclaimer: "Válido para primeira compra,<br> investindo acima de R$ 2.000,00.",
          image: {
            path: {
              desktop: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/silver.webp",
              mobile: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/silver.webp",
            },
            width: 56,
          },
          coupon: {
            code: "OLHAR15",
            title: "Cupom de 15% OFF",
          },
        },
      },
      {
        id: "bronze",
        cta: {
          title: "10%",
          subtitle: "OFF",
          highlight: false,
        },
        content: {
          tag: {
            title: "OLHAR10",
          },
          title: "10% OFF",
          disclaimer: "Válido para compras <br>de qualquer valor.",
          image: {
            path: {
              desktop: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/bronze.webp",
              mobile: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/special-promo/bronze.webp",
            },
            width: 56,
          },
          coupon: {
            code: "OLHAR10",
            title: "Cupom de 10% OFF",
          },
        },
      },
    ],
  },
  discount: {
    title: "Inclua o cupom de desconto no final da<br class='block lg:hidden'/> sua primeira compra",
  },
  disclaimer: {
    title:
      "Esta oferta é válida exclusivamente para a primeira compra, limitada a uma por CPF/CNPJ. Não pode ser combinada com outras promoções ou cupons, exceto para lojistas ou moradores de condomínios com telas da Eletromidia.",
  },
};

const mainHeroContent = {
  text: {
    title: "Tá querendo aparecer?",
    subtitle:
      "Faça parte do dia a dia do consumidor e <br class='max-lg:hidden'> conquiste mais clientes com a Eletromidia",
    backgroundColor: "#FF4F00",
  },
  media: {
    type: "video",
    path: {
      desktop: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/home-aqui-1920x1080-desktop-ffmpeg.mp4",
      mobile: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/home-aqui-640x360-mobile-ffmpeg.mp4",
    },
    thumb: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/home-aqui-thumb.webp",
  },
  cta: {
    label: "Anunciar agora",
    url: "/identificacao",
    icon: "",
    target: "",
    width: 240,
  },
  firstItemMobile: "media",
  firstItemDesktop: "media",
};

const everyoneSees = {
  text: {
    title:
      "Quando você anuncia sua<br class='max-lg:hidden'> empresa com a Eletromidia, <br class='max-lg:hidden'>todo mundo vê!",
    subtitle: "",
    backgroundColor: "#FFF",
  },
  media: {
    type: "image",
    alt: "Duas mulheres felizes se cumprimentando com um toque de mãos em um ambiente de trabalho, cercadas por caixas de papelão e roupas penduradas ao fundo.",
    path: {
      desktop:
        "https://storage.googleapis.com/elt-smb-prod/assets/home/content/anunciar-na-Eletromidia-esta-ainda-mais-acessível.webp",
      mobile:
        "https://storage.googleapis.com/elt-smb-prod/assets/home/content/anunciar-na-Eletromidia-esta-ainda-mais-acessível.webp",
    },
  },
  cta: {
    label: "Anunciar agora",
    url: "/identificacao",
    icon: "",
    target: "",
    width: 240,
  },
  firstItemMobile: "media",
  firstItemDesktop: "text",
};

const carouselContent = {
  title: "Anuncie a sua marca por todos os cantos",
  showIndicators: false,
  ref: "",
  itemStyle: "basic",
  circular: true,
  cta: {
    show: true,
    label: "Anuncie agora",
    url: "/identificacao",
    icon: "",
    target: "",
    width: 240,
  },
  items: [
    {
      title: "Edifícios comerciais",
      description:
        "Com público economicamente ativo, influenciador e decisor, a Eletromidia é liderança absoluta nos elevadores comerciais.",
      image: {
        url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/elt-comercial.webp",
        alt: "Foto de uma tela da Eletromidia dentro de um elevador em um edifício comercial.",
      },
      cta: {
        show: false,
        label: "",
        url: "",
        icon: "",
        target: "",
      },
    },
    {
      title: "Edifícios residenciais",
      description:
        "Fazendo parte de mais de 350 mil lares, as telas nos elevadores residenciais alcançam famílias de diversas gerações, 24 horas por dia.",
      image: {
        url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/elt-residencial.webp",
        alt: "Foto de um homem observando uma tela da Eletromidia, dentro de um elevador em um prédio residencial.",
      },
      cta: {
        show: false,
        label: "",
        url: "",
        icon: "",
        target: "",
      },
    },
    {
      title: "Shoppings",
      description:
        "Um ambiente que traz experiências e conecta as pessoas com as marcas no momento que estão em busca de produtos e serviços.",
      image: {
        url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/elt-shoppings.webp",
        alt: "Um painel de LED suspenso com o logotipo da Eletromidia em um Shopping.",
      },
      cta: {
        show: false,
        label: "",
        url: "",
        icon: "",
        target: "",
      },
    },
    {
      title: "Ruas",
      description:
        "Para alcançar diversos públicos em diversos locais, sua marca pode estar nas ruas fazendo parte da paisagem urbana e se integrando a cidade.",
      image: {
        url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/elt-ruas.webp",
        alt: "Foto de um MUB (Mobiliario Urbano), do tipo ponto de ônibus, com uma tela com o logotipo da Eletromidia.",
      },
      cta: {
        show: false,
        label: "",
        url: "",
        icon: "",
        target: "",
      },
    },
    {
      title: "Transportes",
      description:
        "Leve a sua marca para os principais sistemas de transportes e acompanhe o movimento do seu público.",
      image: {
        url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/elt-transportes.webp",
        alt: "Um painel em uma parede, de uma estação de metrô, com o logotipo da Eletromidia.",
      },
      cta: {
        show: false,
        label: "",
        url: "",
        icon: "",
        target: "",
      },
    },
    // {
    //   title: "Aeroportos",
    //   description:
    //     "Telas de alto impacto que acompanham o público no momento de espera no ambiente e aumentam a visibilidade da sua comunicação.",
    //   image: {
    //     url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/elt-aeroportos.webp",
    //     alt: "Uma tela com o logotipo da Eletromidia, ao lado de telas com informações de voos em um aeroporto.",
    //   },
    //   cta: {
    //     show: false,
    //     label: "",
    //     url: "",
    //     icon: "",
    //     target: "",
    //   },
    // },
  ],
};

const quotesCarousel = {
  title: "Quem anuncia, aprova!",
  showIndicators: false,
  ref: "",
  itemStyle: "quote",
  numVisible: 1,
  circular: true,
  cta: {
    show: true,
    label: "Anuncie agora",
    url: "/identificacao",
    icon: "",
    target: "",
    width: 240,
  },
  items: [
    {
      image: {
        url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/rondelli-remax-imobiliaria-sp.webp",
        alt: "Foto do Rondelli da Remax Imobiliária",
      },
      review:
        "“Estou presente nos outros edifícios e ampliando meus negócios com a ajuda da Eletromidia.<br> Está me trazendo ótimos resultados, por isso eu continuo anunciando.”",
      name: "Rondelli",
      company: "Remax Imobiliária",
      state: "SP",
    },
    {
      image: {
        url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/bruno-grupo-impettus-rj.webp",
        alt: "Foto do Bruno do Grupo Impettus",
      },
      review:
        "“A campanha que fizemos para a unidade Espeto Carioca Leblon foi um sucesso!<br> O franqueado ficou muito feliz com o resultado e já replicou a ideia para outros franqueados! Sem dúvidas iremos repetir a campanha para mais lojas da rede”",
      name: "Bruno",
      company: "Grupo Impettus",
      state: "RJ",
    },
    {
      image: {
        url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/augusto-holder-contabilidade-df.webp",
        alt: "Foto do Auhusto da Holder Contabilidade",
      },
      review:
        "“Com os anúncios nos elevadores, estou captando mais clientes em meu edifício e expandindo meus negócios.”",
      name: "Augusto",
      company: "Holder Contabilidade",
      state: "DF",
    },
    {
      image: {
        url: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/camile-casari-imoveis-sp.webp",
        alt: "Foto da Camile da Casari Imóveis",
      },
      review:
        "“Anunciar com vocês trouxe mais visibilidade para a nossa marca. Foi incrível e bastante satisfatório em relação a visualização do anúncio e o retorno de leads”",
      name: "Camile",
      company: "Casari Imóveis",
      state: "SP",
    },
  ],
};

const partnerProgram = {
  firstItemMobile: "text",
  firstItemDesktop: "media",
  style: "",
  text: {
    title: "Programa de<br> Parcerias Eletromidia",
    subtitle: "Mais possibilidades para a sua agência",
    description:
      "Criado para colaborar com agências de marketing e publicidade <br class='max-lg:hidden'> que atendem PMEs, elevamos a qualidade de entregas dos<br class='max-lg:hidden'> parceiros com estratégias de comunicação completas e muitos<br class='max-lg:hidden'> benefícios exclusivos para alavancar o crescimento da empresa.",
    backgroundColor: "#FFC400",
  },
  media: {
    type: "image",
    alt: "Dois colegas de trabalho sorrindo enquanto olham para um tablet em um ambiente de escritório moderno.",
    path: {
      desktop: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/partner-program.webp",
      mobile: "https://storage.googleapis.com/elt-smb-prod/assets/home/content/partner-program.webp",
    },
    maxHeight: "890px",
  },
  cta: {
    label: "Quero ser parceiro Eletromidia",
    url: "https://parceiros.eletromidia.com.br/",
    icon: "",
    target: "_blank",
    width: 380,
  },
};

const helperContent = {
  title:
    "Precisa de ajuda? <br><span class='mb-4 mt-4 block text-5xl lg:text-7xl'>Fale com o time de especialistas!</span>",
  subtitle: "Atendimento disponível <br class='max-lg:inline-block lg:hidden'>de segunda à sexta, das 9h às 18h",
  image: {
    url: helperBg,
    alt: "Atendimento disponível de segunda à sexta, das 9h às 18h",
  },
  cta: {
    show: true,
    label: "Fale com os especialistas",
    url: "https://wa.me/5511963815044/?text=Falar%20com%20especialista",
    icon: "uil uil-whatsapp ml-4",
    target: "_blank",
  },
};

onMounted(() => {
  if (UserStore.token) {
    mainHeroContent.cta.url = "/resultados";
    everyoneSees.cta.url = "/resultados";

    carouselContent.cta.url = "/resultados";
    carouselContent.items.forEach((item) => {
      item.cta.url = "/resultados";
    });
  }
});

abtest.enableFeature(abtest.FEATURE_HOME_DOBRA);

const featureValue = abtest.getFeatureVariation(abtest.FEATURE_HOME_DOBRA);
const variationOriginal = ref(featureValue === true);
const variationAlternative = ref(featureValue === false);

onMounted(() => {
  nextTick(() => {
    isDomContentLoaded.value = true;
  });
});
</script>

<style scoped lang="scss"></style>
